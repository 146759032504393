// Here you can add other styles

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.wp-50 {
  width: 50%;
}

.mw-mc-custom {
  min-width: 155px;
}

.mw-md-custom {
  min-width: 200px;
}

.mw-mu-custom {
  min-width: 100px;
  max-width: 125px;
}

.nett-font-style {
  font-size: 3rem;
  font-weight: bolder;
  text-align: center;
}

.header-toggler {
  font-size: 1.25rem;
  border-right: 1px solid #d8dbe0;
  border-radius: 0;
  padding-right: 20px;
}

.custom-link:hover {
  text-decoration: none;
}

.google-button {
  border-radius: 0;
  border: 2px solid #d2d2d2;
}

.google-button:hover {
  border: 2px solid #b2b2b2;
}

.header-fs-custom {
  font-size: 35px;
}

.pt-custom {
  padding-top: 12px;
}

.kjtCGA {
  min-width: auto !important;
}

html:not([dir=rtl]) .modal-header .btn-close {
  margin: inherit;
}

.navlink-custom {
  margin-left: 8px !important;
  margin-right: 0 !important;
  padding-right: 0 !important;
}

.alert-login-custom {
  padding-left: 48px;
}

.page-404-row {
  justify-content: center;
  align-items: center;
  height: 75vh;
}

.dtCol .rdt_TableCol_Sortable {
  align-items: normal !important;
}

.custom-width {
  width: 100%;
}

.custom-margin {
  margin: 0 0 8px 0 !important;
}

@media (min-width: 993px) {
  .custom-width {
    width: 50%;
  }
}